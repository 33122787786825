import React, { useState, useEffect } from 'react';
import './App.css';

const App: React.FC = () => {

  const [data, setData] = useState<any | null>(null);

  useEffect(() => {
    // Load the JSON data from the public folder
    fetch('/data.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((jsonData) => {
        setData(jsonData);
      })
      .catch((error) => {
        console.error('Error loading data:', error);
      });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="app">
      <header className="header">
        <h1>{data.meta.companyName}</h1>
        <h4>Technical Documentation Consultancy and Documentation Service Provider</h4>
      </header>
      <div className="content">
        <section className="section">
          <h2>{data.elevatorPitch.section}</h2>
          <p>{data.elevatorPitch.content}</p>
        </section>

        <section className="section">
          <h2>{data.valueProposition.section}</h2>
          <p>{data.valueProposition.content}</p>
          {Object.keys(data.valueProposition.subsections).map((key) => (
            <div key={key} className="subsection">
              <h3>{data.valueProposition.subsections[key].section}</h3>
              <p>{data.valueProposition.subsections[key].content}</p>
            </div>
          ))}
        </section>

        <section className="section">
          <h2>{data.consultingApproach.section}</h2>
          <p>{data.consultingApproach.content}</p>
          <ul>
            {Object.keys(data.consultingApproach.subsections).map((key) => (
              <div key={key} className="subsection">
              
                  <li>{data.consultingApproach.subsections[key]}</li>
                
              </div>
            ))}
          </ul>
        </section>

        <section className="section">
          <h2>{data.conclusion.section}</h2>
          <p>{data.conclusion.content}</p>
        </section>

        <section className="section">
          <h2>Contact Information</h2>
          <p>Email: {data.contactInfo.email}</p>
          <p>LinkedIn: <a href={data.contactInfo.linkedin}>{data.contactInfo.linkedin}</a></p>
          <p>{data.contactInfo.copyright}</p>
        </section>
      </div>
    </div>
  );
};

export default App;
